import {Suspense} from 'react';
import classNames from 'classnames';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {LegendDialog} from './legend';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import MobileSchemas from './schema/Schemas.mobile';
import MobileTable from './table/Table.mobile';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {MobileDisplayModeSwitch} from './DisplayModeSwitch.mobile';
import {SpaceTypeMenu} from './typeFilter/SpaceTypeMenu';
import MobileActions from './actions/Actions.mobile';
import {extractSpacesListData} from '@src/store/modules/entities/spaces/selectors/lists';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {FloorSort} from './sort/FloorSort';
import PageBreadcrumbs from './PageBreadcrumbs';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {MobileTypesFilterLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/MobileTypesFilterLoader';
import {MobileSchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/MobileSchemaLoader';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {ProcessVisibilityFilter} from '@src/pages/SpacesPage/components/filters/ProcessVisibilityFilter';

interface IMobileProps {
	objectId: string;
}

const Mobile = ({objectId}: IMobileProps) => {
	const {displayMode, schemaView} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {selected} = useAppSelector(s => extractSpacesListData(s, objectId));
	const {data: targetObjects} = useTargetObjects(objectId);

	const {state} = useEntitiesFiltersCtx();
	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(
		objectId,
		{
			sectionsMenu,
			mobileLeftButton:
				selected.length > 0 ? <MobileSelectionClearing objectId={objectId} /> : undefined,
			mobileRightButtons: <MobileActions objectId={objectId} />
		},
		[objectId, selected]
	);

	return (
		<div
			className={classNames('page-cont', 'spaces-page', {
				'page-cont_extra-top-margin': sectionsMenu.length
			})}
		>
			<PageBreadcrumbs objectId={objectId} />
			<div className="spaces-page__m-controls-bar">
				<MobileDisplayModeSwitch objectId={objectId} />

				<div className="spaces-page__space-type-menu-btn-wrap">
					<Suspense fallback={<MobileTypesFilterLoader />}>
						{displayMode === SpacesDisplayMode.SCHEMA && targetObjects && (
							<SpaceTypeMenu
								objectId={objectId}
								targetObjects={targetObjects}
							/>
						)}
					</Suspense>
				</div>

				<LegendDialog />

				<MobileFiltersLinkBtn
					state={state}
					objectId={objectId}
					entity="spaces"
				/>
			</div>

			<div className="spaces-page__filter-bar">
				<ProcessVisibilityFilter objectId={objectId} />
				{displayMode === SpacesDisplayMode.SCHEMA &&
					schemaView !== SpacesSchemaVariants.REPORTS && (
						<FloorSort objectId={objectId} />
					)}
			</div>

			{displayMode === SpacesDisplayMode.SCHEMA && targetObjects && (
				<Suspense fallback={<MobileSchemaLoader />}>
					<MobileSchemas
						objectId={objectId}
						targetObjects={targetObjects}
					/>
				</Suspense>
			)}
			{displayMode === SpacesDisplayMode.LIST && <MobileTable objectId={objectId} />}
		</div>
	);
};

export default Mobile;
