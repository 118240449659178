import {useMemo} from 'react';
import {useSpaceChecksAsArray} from '../checks/hooks';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useSpaceInspectionsAsArray} from '../inspections/hooks';
import {useSpaceInternalAcceptancesAsArray} from '../internalAcceptances/hooks';
import {useSpaceOwnerAcceptancesAsArray} from '../ownerAcceptances/hooks';
import {useSpaceProblemsAsArray} from '../problems/hooks';
import {useSpaceWarrantyClaimsAsArray} from '../warrantyClaims/hooks';
import {useWorkAcceptancesForSpaceAsArray} from '@src/core/hooks/queries/workAcceptances/';
import {extractSpaceEntitiesVisibility} from '@src/store/modules/settings/pages/space/selectors';
import {extractSpaceTabs} from '@src/store/modules/entities/space/selectors';
import {extractSpaceEntities} from './selectors';
import {useIsFetching} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMergedSpaceLocalAndLatestChecks} from '@src/core/hooks/queries/checks/hooks';
import {useMergedSpaceLocalAndLatestInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {useMergedSpaceLocalAndLatestWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useMergedSpaceLocalAndLatestOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances';
import {useExtractLocalSpaceProblems} from '@src/core/hooks/queries/problems/hooks';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {useExtractLocalSpaceInspections} from '@src/core/hooks/queries/inspections/hooks';
import {useSpacePresaleChecksAsArray} from '@src/core/hooks/queries/space/presaleChecks/hooks';

export const useSpaceEntities = (objectId: string, spaceId: string) => {
	const roles = useAppSelector(extractUserRoles);
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const spaceEntitiesTabs = useAppSelector(extractSpaceTabs);
	const spaceEntitiesVisibility = useAppSelector(extractSpaceEntitiesVisibility);

	const entitiesPermissions = useMemo(
		() => ({
			spaceChecks: hasPermission(roles, 'checksView'),
			spacePresaleChecks: hasPermission(roles, 'presale-checks-view'),
			spaceInspections: hasPermission(roles, 'inspectionsView'),
			spaceInternalAcceptances: hasPermission(roles, 'internal-acceptances-view'),
			spaceOwnerAcceptances: hasPermission(roles, 'owner-acceptances-view'),
			spaceProblems: hasPermission(roles, 'problemsView'),
			spaceWarrantyClaims: hasPermission(roles, 'warranty-claims-view')
		}),
		[roles]
	);

	const {data: spaceChecks} = useSpaceChecksAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceChecks
	);

	const {data: spacePresaleChecks} = useSpacePresaleChecksAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spacePresaleChecks
	);

	const {data: spaceInspections} = useSpaceInspectionsAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceInspections
	);
	const {data: spaceInternalAcceptances} = useSpaceInternalAcceptancesAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceInternalAcceptances
	);
	const {data: spaceOwnerAcceptances} = useSpaceOwnerAcceptancesAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceOwnerAcceptances
	);
	const {data: spaceProblems} = useSpaceProblemsAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceProblems
	);
	const {data: spaceWarrantyClaims} = useSpaceWarrantyClaimsAsArray(
		objectId,
		spaceId,
		entitiesPermissions.spaceWarrantyClaims
	);

	const {data: spaceWorkAcceptances} = useWorkAcceptancesForSpaceAsArray(objectId, spaceId);

	const localInternalAcceptances = useMergedSpaceLocalAndLatestInternalAcceptances(
		objectId,
		spaceId
	);
	const localWarrantyClaims = useMergedSpaceLocalAndLatestWarrantyClaims(objectId, spaceId);
	const localOwnerAcceptances = useMergedSpaceLocalAndLatestOwnerAcceptances(objectId, spaceId);
	const {data: localInspections} = useExtractLocalSpaceInspections(objectId, spaceId);
	const {data: localProblems} = useExtractLocalSpaceProblems(objectId, spaceId);
	const localChecks = useMergedSpaceLocalAndLatestChecks(objectId, spaceId);

	return useMemo(() => {
		if (appIsOffline) {
			return extractSpaceEntities({
				localWarrantyClaims,
				localInternalAcceptances,
				localOwnerAcceptances,
				localChecks,
				localProblems,
				localInspections,
				isOffline: appIsOffline,
				entitiesTab: spaceEntitiesTabs,
				visibility: spaceEntitiesVisibility
			});
		}

		return extractSpaceEntities({
			checks: spaceChecks,
			presaleChecks: spacePresaleChecks,
			inspections: spaceInspections,
			internalAcceptances: spaceInternalAcceptances,
			ownerAcceptances: spaceOwnerAcceptances,
			problems: spaceProblems,
			claims: spaceWarrantyClaims,
			workAcceptances: spaceWorkAcceptances,
			entitiesTab: spaceEntitiesTabs,
			visibility: spaceEntitiesVisibility,
			localWarrantyClaims,
			localInternalAcceptances,
			localOwnerAcceptances,
			localProblems,
			localChecks,
			localInspections,
			isOffline: appIsOffline
		});
	}, [
		spaceChecks,
		spaceInspections,
		spaceInternalAcceptances,
		spaceOwnerAcceptances,
		spaceProblems,
		spaceWarrantyClaims,
		spaceEntitiesTabs,
		spaceEntitiesVisibility,
		localWarrantyClaims,
		spaceWorkAcceptances,
		localInternalAcceptances,
		localOwnerAcceptances,
		localProblems,
		appIsOffline,
		localInspections,
		localChecks,
		spacePresaleChecks
	]);
};

export const useSpaceEntitiesIsLoading = () => {
	const checksIsLoading = !!useIsFetching({queryKey: spacesQueryKeys.checks()});
	const presaleChecksIsLoading = !!useIsFetching({queryKey: spacesQueryKeys.presaleChecks()});
	const inspectionsIsLoading = !!useIsFetching({queryKey: spacesQueryKeys.inspections()});
	const internalAcceptancesIsLoading = !!useIsFetching({
		queryKey: spacesQueryKeys.internalAcceptances()
	});
	const ownerAcceptancesIsLoading = !!useIsFetching({
		queryKey: spacesQueryKeys.ownerAcceptances()
	});
	const problemsIsLoading = !!useIsFetching({queryKey: spacesQueryKeys.problems()});
	const warrantyClaimsIsLoading = !!useIsFetching({queryKey: spacesQueryKeys.warrantyClaims()});

	return (
		checksIsLoading ||
		presaleChecksIsLoading ||
		inspectionsIsLoading ||
		internalAcceptancesIsLoading ||
		ownerAcceptancesIsLoading ||
		problemsIsLoading ||
		warrantyClaimsIsLoading
	);
};
