import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export type IDeletePresaleCheckResponse = IPresaleCheck;

/**
 * Удаляет предпродажную проверку
 *
 * @param presaleCheckId id предпродажной проверки
 */
export const makePresaleCheckDeleteRequest = async (objectId: string, presaleCheckId: string) => {
	const response = await httpRequests.withToken.delete<IDeletePresaleCheckResponse>(
		`presale-checks/${presaleCheckId}`,
		{params: {objectId}}
	);

	return response.data;
};
