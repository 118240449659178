import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {
	IMaceEditFieldsSettingsSetRequest,
	requestEditFieldsSettingsSet
} from '@/shared/api/fieldsSettingsSets/editFieldsSettingsSet';

export const useEditFieldsSettingsSet = () =>
	useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.edit(),
		mutationFn: (data: IMaceEditFieldsSettingsSetRequest) => requestEditFieldsSettingsSet(data),
		onError: () => addErrorToast('Ошибка', 'при обновлении набора настроек полей'),
		onSuccess: data => {
			queryClient.setQueryData(fieldSettingsSetsQueryKeys.detail(data.id), set =>
				set ? {...set, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
