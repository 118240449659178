import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {
	IEditFieldSettingsOrderRequest,
	requestEditFieldSettingsOrder
} from '@/shared/api/fieldSettings/editFieldSettingsOrder';

export const useEditFieldSettingsOrder = () =>
	useMutation({
		mutationKey: fieldSettingsQueryKeys.edit(),
		mutationFn: (data: IEditFieldSettingsOrderRequest) => requestEditFieldSettingsOrder(data),
		onError: () => addErrorToast('Ошибка', 'при изменении настроек полей'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.all()});
		}
	});
