import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {Dispatch, MutableRefObject, RefObject, SetStateAction} from 'react';

interface ISavePresaleCheckProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	selectedPresaleCheckId?: string;
	setSaving: Dispatch<SetStateAction<boolean>>;

	success: MutableRefObject<boolean>;
	onClose: () => void;
}

export const savePresaleCheck = async ({
	addingEntityRef,
	selectedPresaleCheckId,
	setSaving,
	success,
	onClose
}: ISavePresaleCheckProps) => {
	if (addingEntityRef.current) {
		const presaleCheckId = selectedPresaleCheckId;
		const extraLinks: IProblem['links'] | IInspection['links'] = {presaleCheckId};
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}
		if (addingEntityRef.current.saveCache && savingData) {
			if (presaleCheckId === undefined) {
				setSaving(true);
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else {
				const cachedData = await addingEntityRef.current.saveCache(savingData, extraLinks);
				if (cachedData) {
					await addingEntityRef.current.save(savingData, extraLinks, cachedData.cacheKey);
				}
			}
		}
	} else {
		onClose();
	}
	success.current = true;
	if (success.current) {
		onClose();
		setSaving(false);
	}
};
