import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {
	IAddFieldSettingsRequest,
	requestAddFieldSettings
} from '@/shared/api/fieldSettings/addFieldSettings';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';

export const useAddFieldSettings = () =>
	useMutation({
		mutationKey: fieldSettingsQueryKeys.add(),
		mutationFn: (data: IAddFieldSettingsRequest) => requestAddFieldSettings(data),
		onError: () => addErrorToast('Ошибка', 'при добавлении настроек полей'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.list()});
		}
	});
