import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {SpaceEntitiesTab} from '@src/store/modules/entities/space/reducers';
import {SpaceEntitiesVisibility} from '@src/store/modules/settings/pages/space/reducers/entitiesVisibility';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

const convert = <T extends {id: string}, E extends string, S>(
	data: T,
	entityName: E,
	subRows?: S[]
) => ({
	id: `${entityName}_${data.id}`,
	type: entityName,
	data,
	expanded: !!subRows?.length,
	subRows
});

const sortByCreation = (a: ISpaceEntity, b: ISpaceEntity) =>
	(b.data.createdAt ?? 0) - (a.data.createdAt ?? 0);

class EntitiesMap {
	private data: Map<string, ISpaceEntity[]> = new Map();

	get(key: string) {
		return this.data.get(key)?.sort(sortByCreation);
	}

	add(key: string, value: ISpaceEntity) {
		const prevSpaceEntity = this.data.get(key);

		if (prevSpaceEntity) {
			prevSpaceEntity.push(value);
			this.data.set(key, prevSpaceEntity);
		} else {
			this.data.set(key, [value]);
		}
	}
}

interface IExtractSpaceEntitiesArgs {
	checks?: IListCheck[];
	presaleChecks?: IPresaleCheck[];
	inspections?: IListInspection[];
	internalAcceptances?: IListInternalAcceptance[];
	ownerAcceptances?: IListOwnerAcceptance[];
	problems?: IListProblem[];
	claims?: IListWarrantyClaim[];
	workAcceptances?: IListWorkAcceptance[];
	entitiesTab?: SpaceEntitiesTab;
	visibility: SpaceEntitiesVisibility[];
	localChecks?: IListCheck[];
	localInspections?: IListInspection[];
	localWarrantyClaims?: IListWarrantyClaim[];
	localInternalAcceptances?: IListInternalAcceptance[];
	localOwnerAcceptances?: IListOwnerAcceptance[];
	localProblems?: IListProblem[];
	isOffline?: boolean;
}

const processEntities = <T extends {id: string}, E extends string>(
	entitiesSubRows: EntitiesMap,
	entityType: E,
	entities: T[],
	isOffline?: boolean
) => {
	if (isOffline) {
		entities = entities.filter(item => entitiesSubRows.get(item.id)?.length);
	}
	return entities.map(item => convert(item, entityType, entitiesSubRows.get(item.id)));
};

// todo Зарефакторить и сделать более читаемым

export const extractSpaceEntities = ({
	checks,
	presaleChecks,
	inspections,
	internalAcceptances,
	ownerAcceptances,
	problems,
	claims,
	workAcceptances,
	entitiesTab,
	visibility,
	localInternalAcceptances,
	localOwnerAcceptances,
	localInspections,
	localWarrantyClaims,
	localProblems,
	isOffline,
	localChecks
}: IExtractSpaceEntitiesArgs) => {
	const checksSubRows = new EntitiesMap();
	const presaleChecksSubRows = new EntitiesMap();
	const acceptancesSubRows = new EntitiesMap();
	const internalAcceptancesSubRows = new EntitiesMap();
	const claimsSubRows = new EntitiesMap();
	const workAcceptancesSubRows = new EntitiesMap();

	const allVisible = entitiesTab === 'all' || entitiesTab === undefined;
	const checksVisible = allVisible || entitiesTab === 'checks';
	const presaleChecksVisible = allVisible || entitiesTab === 'presaleChecks';
	const internalAcceptancesVisible = allVisible || entitiesTab === 'internal-acceptances';
	const acceptancesVisible = allVisible || entitiesTab === 'owner-acceptances';
	const claimsVisible = allVisible || entitiesTab === 'warranty-claims';
	const workAcceptancesVisible = allVisible || entitiesTab === 'work-acceptances';
	const problemsVisible = visibility.includes('problems');
	const inspectionsVisible = visibility.includes('inspections');

	let result: ISpaceEntity[] = [];

	if (problemsVisible) {
		if (problems) {
			for (const problem of problems) {
				if (problem.links) {
					if (problem.links.workAcceptanceId) {
						if (workAcceptancesVisible) {
							workAcceptancesSubRows.add(
								problem.links.workAcceptanceId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.checkId) {
						if (checksVisible) {
							checksSubRows.add(problem.links.checkId, convert(problem, 'problem'));
						}
					} else if (problem.links.presaleCheckId) {
						if (presaleChecksVisible) {
							presaleChecksSubRows.add(
								problem.links.presaleCheckId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.ownerAcceptanceId) {
						if (acceptancesVisible) {
							acceptancesSubRows.add(
								problem.links.ownerAcceptanceId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.internalAcceptanceId) {
						if (internalAcceptancesVisible) {
							internalAcceptancesSubRows.add(
								problem.links.internalAcceptanceId,
								convert(problem, 'problem')
							);
						}
					} else if (problem.links.warrantyClaimId) {
						if (claimsVisible) {
							claimsSubRows.add(
								problem.links.warrantyClaimId,
								convert(problem, 'problem')
							);
						}
					} else if (allVisible) {
						result.push(convert(problem, 'problem'));
					}
				}
			}
		}
		if (localProblems) {
			for (const localProblem of localProblems) {
				if (localProblem.links?.checkId) {
					if (checksVisible) {
						checksSubRows.add(
							localProblem.links.checkId,
							convert(localProblem, 'local-problem')
						);
					}
				} else if (localProblem.links?.internalAcceptanceId) {
					if (internalAcceptancesVisible) {
						internalAcceptancesSubRows.add(
							localProblem.links.internalAcceptanceId,
							convert(localProblem, 'local-problem')
						);
					}
				} else if (localProblem.links?.ownerAcceptanceId) {
					if (acceptancesVisible) {
						acceptancesSubRows.add(
							localProblem.links.ownerAcceptanceId,
							convert(localProblem, 'local-problem')
						);
					}
				} else if (localProblem.links?.warrantyClaimId) {
					if (claimsVisible) {
						claimsSubRows.add(
							localProblem.links.warrantyClaimId,
							convert(localProblem, 'local-problem')
						);
					}
				}
			}
		}
	}

	if (inspectionsVisible) {
		if (inspections) {
			for (const inspection of inspections) {
				if (inspection.links) {
					if (inspection.links.workAcceptanceId) {
						if (workAcceptancesVisible) {
							workAcceptancesSubRows.add(
								inspection.links.workAcceptanceId,
								convert(inspection, 'inspection')
							);
						}
					} else if (inspection.links.checkId) {
						if (checksVisible) {
							checksSubRows.add(
								inspection.links.checkId,
								convert(inspection, 'inspection')
							);
						}
					} else if (inspection.links.presaleCheckId) {
						if (checksVisible) {
							presaleChecksSubRows.add(
								inspection.links.presaleCheckId,
								convert(inspection, 'inspection')
							);
						}
					} else if (inspection.links.internalAcceptanceId) {
						if (internalAcceptancesVisible) {
							internalAcceptancesSubRows.add(
								inspection.links.internalAcceptanceId,
								convert(inspection, 'inspection')
							);
						}
					} else if (allVisible) {
						result.push(convert(inspection, 'inspection'));
					}
				}
			}
		}
		if (localInspections) {
			for (const localInspection of localInspections) {
				if (localInspection.links?.checkId) {
					if (checksVisible) {
						checksSubRows.add(
							localInspection.links.checkId,
							convert(localInspection, 'local-inspection')
						);
					}
				}
				if (localInspection.links?.internalAcceptanceId) {
					if (internalAcceptancesVisible) {
						internalAcceptancesSubRows.add(
							localInspection.links.internalAcceptanceId,
							convert(localInspection, 'local-inspection')
						);
					}
				}
			}
		}
	}

	if (checksVisible && checks) {
		result = result.concat(processEntities(checksSubRows, 'check', checks, isOffline));
	}
	if (checksVisible && localChecks) {
		result = result.concat(
			processEntities(checksSubRows, 'local-check', localChecks, isOffline)
		);
	}
	if (presaleChecksVisible && presaleChecks) {
		result = result.concat(
			processEntities(presaleChecksSubRows, 'presale-check', presaleChecks, isOffline)
		);
	}
	if (internalAcceptancesVisible && internalAcceptances) {
		result = result.concat(
			processEntities(
				internalAcceptancesSubRows,
				'internal-acceptance',
				internalAcceptances,
				isOffline
			)
		);
	}
	if (internalAcceptancesVisible && localInternalAcceptances) {
		result = result.concat(
			processEntities(
				internalAcceptancesSubRows,
				'local-internal-acceptance',
				localInternalAcceptances,
				isOffline
			)
		);
	}

	if (acceptancesVisible && ownerAcceptances) {
		result = result.concat(
			processEntities(acceptancesSubRows, 'owner-acceptance', ownerAcceptances, isOffline)
		);
	}
	if (acceptancesVisible && localOwnerAcceptances) {
		result = result.concat(
			processEntities(
				acceptancesSubRows,
				'local-owner-acceptance',
				localOwnerAcceptances,
				isOffline
			)
		);
	}
	if (claimsVisible && claims) {
		result = result.concat(processEntities(claimsSubRows, 'warranty-claim', claims, isOffline));
	}
	if (claimsVisible && localWarrantyClaims) {
		result = result.concat(
			processEntities(claimsSubRows, 'local-warranty-claim', localWarrantyClaims, isOffline)
		);
	}

	if (workAcceptancesVisible && workAcceptances) {
		result = result.concat(
			processEntities(workAcceptancesSubRows, 'work-acceptance', workAcceptances, isOffline)
		);
	}

	return result.sort(sortByCreation);
};
