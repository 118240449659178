import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {deleteFieldsSettingsSetRequest} from '@/shared/api/fieldsSettingsSets';

export const useDeleteFieldsSettingsSet = () =>
	useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.edit(),
		mutationFn: (setId: string) => deleteFieldsSettingsSetRequest(setId),
		onError: () => addErrorToast('Ошибка', 'при удалении набора настроек полей'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
