import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IPresaleCheckDetailQueryKey,
	presaleChecksQueryKeys,
	IPresaleCheckInspectionsQueryKey,
	IPresaleCheckProblemsQueryKey,
	IPresaleCheckProblemRepliesQueryKey
} from '../keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {requestListInspections} from '@src/api/backend/inspections';
import {requestListProblems} from '@src/api/backend/problems';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {requestPresaleCheck} from '@/shared/api/presaleChecks/get';
import {requestPresaleChecks} from '@/shared/api/presaleChecks/getPaginate';

export const usePresaleChecksQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	queryClient.setQueryDefaults(presaleChecksQueryKeys.list(), {
		queryFn: async () => requestPresaleChecks(),
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckDetailQueryKey>) => {
			const [, , presaleCheckId] = queryKey;
			return requestPresaleCheck(presaleCheckId);
		},
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.inspections(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckInspectionsQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					presaleChecks: [presaleCheckId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckProblemsQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					presaleChecks: [presaleCheckId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.problemReplies(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckProblemRepliesQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;

			return requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					presaleChecks: [presaleCheckId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
