import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export interface IGetPresaleCheckResponse extends INormalizedData<IPresaleCheck> {
	offset: number;
	limit: number;
	total: number;
}

export const requestPresaleChecks = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetPresaleCheckResponse>(
		'presale-checks/get-paginate',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);

	return response.data;
};
