import {memo, useCallback, useRef, useState} from 'react';
import styles from './AddingPresaleCheckDialog.module.less';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEntityType} from '../../hooks/useEntityType';
import {useAvailableTypes} from '../../hooks/useAvailableTypes';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {usePresaleCheckMaps} from '../../hooks/usePresaleCheckMaps';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useSpace} from '@src/core/hooks/queries/space';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import {savePresaleCheck} from '../../utils/savePresaleCheck';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IAddingInspectionRefProps} from '@src/components/AddingInspection';

interface IAddingPresaleCheckDialogProps {
	objectId: string;
	presaleCheckId?: string;
	links?: IPresaleCheck['links'];
	types?: IPresaleCheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
	title: string;
}

const addingPresaleCheckDialogClassNames = {
	root: styles.addingPresaleCheckDialog,
	header: styles.dialogHeader,
	body: styles.dialogBody,
	title: styles.dialogTitle
};

const AddingPresaleCheckDialogComponent = (props: IAddingPresaleCheckDialogProps) => {
	const {
		objectId,
		presaleCheckId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		onClose,
		defaultProblemData,
		defaultInspectionData,
		title
	} = props;
	const {t} = useTranslation();
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);

	const [selectedPresaleCheckId, setSelectedPresaleCheckId] = useState<string | undefined>(
		presaleCheckId
	);

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);

	const availableTypes = useAvailableTypes(objectId, types, {createdBy});
	const type = useEntityType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const stage = ObjectStageIds.ACCEPTANCE;
	const processId = ProcessIds.PRESALE_CONTROL;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();
	const [contentMap, entitiesSelectMapProps] = usePresaleCheckMaps({
		objectId,
		presaleCheckId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedPresaleCheckId,
		setSelectedPresaleCheckId,
		defaultProblemData,
		defaultInspectionData,
		onClose
	});

	const handleSave = useCallback(async () => {
		await savePresaleCheck({
			addingEntityRef,
			selectedPresaleCheckId,
			setSaving,
			success,
			onClose
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [onClose, onSuccess, selectedPresaleCheckId]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
	}, [addingEntityRef, onClose]);

	return (
		<ModalDialog
			dataTestId="AddingPresaleCheckDialog"
			className={addingPresaleCheckDialogClassNames}
			open={isOpen}
			title={title}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('addingPresaleCheckDialog.dialog.footer.actionButtons.cancel')}
						disabled={saving}
						onClick={handleCancel}
					/>
					<Button
						type="accent-blue"
						label={t('addingPresaleCheckDialog.dialog.footer.actionButtons.save')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogTitle'
					)}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					stagesSelectDialogSaveLabel={t(
						'addingCheckDialog.stagesSelectDialog.saveLabel'
					)}
					stagesSelectDialogCancelLabel={t(
						'addingCheckDialog.stagesSelectDialog.cancelLabel'
					)}
					stages={stages}
					selectedStage={stage}
					selectedProcess={processId}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
					disable
				/>
			)}
		</ModalDialog>
	);
};

export const AddingPresaleCheckDialog = memo(AddingPresaleCheckDialogComponent);
