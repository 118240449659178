import {requestChecks} from '@src/api/backend/checks';
import {requestListInspections} from '@src/api/backend/inspections';
import {requestInternalAcceptances} from '@src/api/backend/internalAcceptances';
import {requestOwnerAcceptances} from '@src/api/backend/ownerAcceptances';
import {requestListProblems} from '@src/api/backend/problems';
import {requestSpace} from '@src/api/backend/space';
import {requestSpaces} from '@src/api/backend/spaces';
import {requestTasks} from '@src/api/backend/tasks';
import {requestWarrantyClaims} from '@src/api/backend/warrantyClaims';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {spacesQueryKeys} from '../keys';
import {
	ISpaceChecksQueryKey,
	ISpaceInspectionsQueryKey,
	ISpaceInternalAcceptancesQueryKey,
	ISpaceOwnerAcceptancesQueryKey,
	ISpacePresaleChecksQueryKey,
	ISpaceProblemRepliesQueryKey,
	ISpaceProblemsQueryKey,
	ISpaceQueryKey,
	ISpaceTasksQueryKey,
	ISpaceWarrantyClaimsQueryKey
} from '../keys/interfaces';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {requestPresaleChecks} from '@/shared/api/presaleChecks/getPaginate';

export const useSpacesQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(spacesQueryKeys.list(), {
		queryFn: async ({queryKey}) =>
			requestSpaces(
				queryKey[2] as Record<string, unknown>,
				queryKey[3] as Record<string, boolean>,
				queryKey[4] as number,
				queryKey[5] as number
			),
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});

	queryClient.setQueryDefaults(spacesQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceQueryKey>) => {
			const [, , spaceId, objectId] = queryKey;
			if (!spaceId || !objectId) return undefined;
			return requestSpace(spaceId, objectId);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.checks(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceChecksQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestChecks(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.presaleChecks(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpacePresaleChecksQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestPresaleChecks(
				{
					objectIds: [objectId],
					spaceIds: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.inspections(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceInspectionsQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.internalAcceptances(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceInternalAcceptancesQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestInternalAcceptances(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.ownerAcceptances(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceOwnerAcceptancesQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestOwnerAcceptances(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.problemReplies(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceProblemRepliesQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					spaces: [spaceId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceProblemsQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.tasks(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceTasksQueryKey>) => {
			const [, , spaceId] = queryKey;
			return requestTasks({spaces: [spaceId]}, {createdAt: false}, 0, 1000);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(spacesQueryKeys.warrantyClaims(), {
		queryFn: async ({queryKey}: QueryFunctionContext<ISpaceWarrantyClaimsQueryKey>) => {
			const [, , objectId, spaceId] = queryKey;
			return requestWarrantyClaims(
				{
					objects: [objectId],
					spaces: [spaceId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
