import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {deleteCustomFieldRequest} from '@/shared/api/fields/deleteCustomField';

export const useDeleteCustomFiled = () =>
	useMutation({
		mutationKey: fieldsQueryKeys.deleteCustom(),
		mutationFn: (fieldId: string) => deleteCustomFieldRequest(fieldId),
		onError: () => addErrorToast('Ошибка', 'при удалении пользовательского поля'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
