import {useQueryClient} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';
import {makePresaleCheckDeleteRequest} from '@/shared/api/presaleChecks/delete';
import {makePresaleCheckEditRequest} from '@/shared/api/presaleChecks/edit';

export interface IDeletePresaleCheckParams {
	presaleCheckId: string;
	objectId: string;
}

export interface IEditPresaleCheckParams {
	presaleCheckId: string;
	objectId: string;
	fields: ISavingPresaleCheck;
}

export const usePresaleChecksMutationDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setMutationDefaults(presaleChecksQueryKeys.delete(), {
		onMutate: (params: IDeletePresaleCheckParams) => ({variables: params}),
		mutationFn: async (params: IDeletePresaleCheckParams) => {
			const {objectId, presaleCheckId} = params;
			return makePresaleCheckDeleteRequest(objectId, presaleCheckId);
		},
		onSuccess: () => {
			addSuccessToast('Удалено', 'Предпродажная проверка успешно удалена');
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.presaleChecks()]
			});
		},
		onError: () => addErrorToast('Ошибка', 'при удалении предпродажной проверки')
	});

	queryClient.setMutationDefaults(presaleChecksQueryKeys.edit(), {
		onMutate: (params: IEditPresaleCheckParams) => ({variables: params}),
		mutationFn: async (params: IEditPresaleCheckParams) => {
			const {objectId, presaleCheckId, fields} = params;
			return makePresaleCheckEditRequest(objectId, presaleCheckId, fields);
		},
		onSuccess: () => {
			addSuccessToast('Удалено', 'Предпродажная проверка успешно изменена');
			void queryClient.invalidateQueries({
				queryKey: [...presaleChecksQueryKeys.details()]
			});
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.presaleChecks()]
			});
		},
		onError: () => addErrorToast('Ошибка', 'при изменении предпродажной проверки')
	});
};
