import {useCallback, useState} from 'react';
import {
	ActionButtons,
	Button,
	EntityGridItem,
	InlineButton,
	ReadMore,
	TextField
} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useEditPresaleCheck} from '@/features/PresaleCheck/model/hooks/useEditPresaleCheck';
import styles from '../PresaleCheckPage.module.less';

interface PresaleCheckDescriptionProps {
	objectId: string;
	presaleCheckId: string;
	value: string | undefined;
	canEdit?: boolean;
}

const descriptionIcon = <i className="tz-description-20" />;
const editIcon = <i className="tz-edit-16" />;

export const PresaleCheckDescriptionDesktop = ({
	objectId,
	presaleCheckId,
	value,
	canEdit
}: PresaleCheckDescriptionProps) => {
	const {t} = useTranslation();

	const [isEditable, setIsEditable] = useState(false);
	const [text, setText] = useState(value);
	const {mutateAsync: editPresaleCheck, isPending: saving} = useEditPresaleCheck();

	const handleChange = useCallback((v: string) => {
		setText(v);
	}, []);

	const handleSave = useCallback(async () => {
		try {
			await editPresaleCheck({objectId, presaleCheckId, fields: {description: text}});
		} finally {
			setIsEditable(false);
		}
	}, [text, presaleCheckId, objectId, editPresaleCheck]);

	return (
		<EntityGridItem
			label={t('presaleChecksPage.description.title')}
			icon={descriptionIcon}
			fullRow
			buttons={
				canEdit && !isEditable ? (
					<InlineButton
						type="accent"
						leftIcon={editIcon}
						onClick={() => setIsEditable(true)}
					/>
				) : null
			}
		>
			{isEditable ? (
				<TextField
					value={text}
					cleanable
					elementType="textarea"
					onChange={handleChange}
					className={styles.documentsDescription}
				/>
			) : value ? (
				<ReadMore
					className={{
						root: styles.documentsDescription
					}}
					text={value}
					maxLength={100}
				/>
			) : null}
			{isEditable && (
				<ActionButtons className={styles.infoButtons}>
					<Button
						type="cancel"
						label={t('presaleChecksPage.description.cancel')}
						disabled={saving}
						onClick={() => {
							setText(value);
							setIsEditable(false);
						}}
					/>
					<Button
						type="accent-blue"
						label={t('presaleChecksPage.description.save')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			)}
		</EntityGridItem>
	);
};
