import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {ISavingFieldsSettingsSet} from '@src/interfaces/saving/ISavingFieldsSettingsSet';
import {requestAddFieldsSettingsSet} from '@/shared/api/fieldsSettingsSets/addFieldsSettingsSet';

export const useAddFieldsSettingsSet = () =>
	useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.add(),
		mutationFn: (fields: ISavingFieldsSettingsSet) => requestAddFieldsSettingsSet(fields),
		onError: () => addErrorToast('Ошибка', 'при добавлении набора настроек полей'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
