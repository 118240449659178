import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useCallback} from 'react';
import {useDeleteCustomFiled} from '@/entities/Fields';

export const useDeleteCustomFieldsDialog = (setIds: string[]) => {
	const {mutateAsync: deleteCustomField} = useDeleteCustomFiled();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить пользовательские поля',
		'Вы действительно хотите удалить выбранные пользовательские поля?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		const deletedFieldsIds = [];
		if (await getDeleteConfirmation()) {
			for (const fieldId of setIds) {
				const deletedField = await deleteCustomField(fieldId);
				deletedFieldsIds.push(deletedField.id);
			}
		}
		return deletedFieldsIds;
	}, [setIds, getDeleteConfirmation, deleteCustomField]);

	return {deleteDialog, handleDelete};
};
