import {memo, useCallback} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useDeleteFieldsSettingsSet} from '@/entities/FieldsSettingsSets';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IActionMenuProps {
	setId: string;
}

const iconDelete = <i className="tz-delete" />;
export const ActionsMenu = memo(({setId}: IActionMenuProps) => {
	const {pushPath} = useChangePath();
	const isDesktop = useIsDesktop();
	const {canDeleteSettingsSet} = useFieldsPermissions();
	const {mutateAsync: deleteFieldsSettingsSet} = useDeleteFieldsSettingsSet();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить набор',
		'Вы действительно хотите удалить набор?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			const deletedSet = await deleteFieldsSettingsSet(setId);
			if (deletedSet) {
				pushPath('/manage/fields');
			}
		}
	}, [deleteFieldsSettingsSet, setId, getDeleteConfirmation, pushPath]);

	const items = [
		<MenuItem
			key="delete"
			icon={iconDelete}
			onClick={handleDelete}
		>
			Удалить набор
		</MenuItem>
	];

	if (canDeleteSettingsSet) {
		return (
			<>
				<IconMenu type={isDesktop ? 'accent-blue' : 'common'}>{items}</IconMenu>
				{deleteDialog}
			</>
		);
	}

	return null;
});
