import {useMemo} from 'react';
import './StructurePage.less';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {extractStructure} from '@src/store/modules/entities/structure/selectors';
import {
	getStructure,
	getStructureInspections,
	getStructureProblems
} from '@src/store/modules/entities/structure/actions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {Desktop} from './components/Desktop';
import {Mobile} from './components/Mobile';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {getCheckRecords, getProblems} from '@src/store/modules/pages/checkLists/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptancesForStructureAsArray} from '@src/core/hooks/queries/workAcceptances';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const StructurePage = () => {
	useScrollRestoration();

	const {objectId, spaceId, structureId} = useStrictParams<{
		objectId: string;
		spaceId: string;
		structureId: string;
	}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const structure = useAppSelector(extractStructure);
	const structureType = useMemo(() => (structure ? [structure.type] : []), [structure]);

	const checkLists = useAvailableCheckLists(
		objectId,
		spaceId,
		ObjectStageIds.BUILDING,
		ProcessIds.ACCEPTANCE_CONTROL,
		structureType
	);

	useAsync(async () => {
		await dispatch(getStructure(objectId, structureId));
		await Promise.all<unknown>([
			dispatch(getStructureProblems(objectId, structureId)),
			dispatch(getStructureInspections(objectId, structureId))
		]);
	}, []);

	const {data: workAcceptances} = useWorkAcceptancesForStructureAsArray(objectId, structure?.id);
	const workAcceptancesIds = useMemo(
		() => workAcceptances?.map(workAcceptance => workAcceptance.id),
		[workAcceptances]
	);

	useAsync(async () => {
		if (checkLists.length) {
			const ids = checkLists.map(l => l.id);
			await dispatch(getProblems(ids, undefined, workAcceptancesIds));
			await dispatch(getCheckRecords(ids, [objectId], undefined, workAcceptancesIds));
		}
	}, [objectId, checkLists, workAcceptancesIds]);

	if (isDesktop) {
		return (
			<Desktop
				objectId={objectId}
				structureId={structureId}
				structure={structure}
				checkLists={checkLists}
				workAcceptances={workAcceptances}
			/>
		);
	}

	return (
		<Mobile
			objectId={objectId}
			structureId={structureId}
			structure={structure}
			checkLists={checkLists}
			workAcceptances={workAcceptances}
		/>
	);
};
