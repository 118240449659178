import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {requestDeleteFieldSettings} from '@/shared/api/fieldSettings/deleteFieldSettings';

export const useDeleteFieldSettings = () =>
	useMutation({
		mutationKey: fieldSettingsQueryKeys.delete(),
		mutationFn: (settingsId: string) => requestDeleteFieldSettings(settingsId),
		onError: () => addErrorToast('Ошибка', 'при удалении настроек полей'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.list()});
		}
	});
