const domain = 'settings/pages/spaces';

export const CHANGE_DISPLAY_MODE = `${domain}/display-mode/change`;
export const CHANGE_SCHEMA_VIEW = `${domain}/schema-view/change`;
export const CHANGE_STAGE = `${domain}/stage/change`;
export const CHANGE_PROCESS = `${domain}/process/change`;
export const CHANGE_TYPE = `${domain}/type/change`;
export const CHANGE_FILTERS = `${domain}/filters/change`;
export const CLEAR_FILTERS = `${domain}/filters/clear`;
export const CHANGE_SORT = `${domain}/sort/change`;
export const CHANGE_PAGE_SIZE = `${domain}/page-size/change`;
export const CHANGE_FLOOR_SORT = `${domain}/floor-sort/change`;
export const TOGGLE_PENDING = `${domain}/pending/toggle`;
export const CHANGE_SCHEMA_PAGE_SIZE = `${domain}/schema-page-size/change`;
export const CHANGE_SCHEMA_OFFSET = `${domain}/schema-offset/change`;

export const TOGGLE_ALT_NAMES_VISIBILITY = `${domain}/alt-names-visibility/toggle`;
