import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export type IEditPresaleCheckResponse = IPresaleCheck;

/**
 * Изменяет проверку
 *
 * @param objectId id объекта
 * @param presaleCheckId id предпродажной проверки
 * @param fields данные проверки
 */
export const makePresaleCheckEditRequest = async (
	objectId: string,
	presaleCheckId: string,
	fields: ISavingPresaleCheck
) => {
	const response = await httpRequests.withToken.put<IEditPresaleCheckResponse>(
		`presale-checks/${presaleCheckId}`,
		{
			objectId,
			...fields
		}
	);

	return response.data;
};
