import {useState} from 'react';
import {PageBreadcrumbs} from '../../components/PageBreadcrumbs';
import {PresaleCheckInfoDesktop} from '../PresaleCheckInfoDesktop';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Plate} from '@tehzor/ui-components';
import {DesktopEntityDocs} from '@src/components/EntityDocs';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {EntityLinks} from '@src/components/EntityLinks/EntityLinks';
import {ActionsDesktop} from '@/features/PresaleCheck/components/ActionsDesktop';
import {PresaleCheckEntitiesTable} from '@/widgets/PresaleCheckEntitiesTable';
import {usePresaleCheckEntitiesColumns} from '@/widgets/PresaleCheckEntitiesTable/hooks/usePresaleCheckEntitiesColumns';
import styles from '../PresaleCheckPage.module.less';
import classNames from 'classnames';

interface IPresaleCheckPageDesktopProps {
	objectId: string;
	presaleCheckId: string;
	presaleCheck?: IPresaleCheck;
}

export const PresaleCheckPageDesktop = ({
	objectId,
	presaleCheckId,
	presaleCheck
}: IPresaleCheckPageDesktopProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const {t} = useTranslation();

	useAppHeader(
		{
			title: presaleCheck
				? t('presaleChecksPage.header.title', {number: presaleCheck.number})
				: '',
			showBackBtn: true
		},
		[presaleCheck]
	);

	const columns = usePresaleCheckEntitiesColumns({isDesktop: true, objectId, presaleCheckId});

	if (!presaleCheck) return null;

	return (
		<div className={classNames('page-cont', styles.presaleCheckPage)}>
			<PageBreadcrumbs
				objectId={objectId}
				presaleCheck={presaleCheck}
			/>

			<div className={styles.dColumns}>
				<div className={classNames(styles.dColumn, styles.dColumnInfo)}>
					<PresaleCheckInfoDesktop
						objectId={objectId}
						presaleCheck={presaleCheck}
					/>
					<DesktopEntityDocs id={presaleCheck.id} />

					{presaleCheck?.links && Object.keys(presaleCheck.links).length !== 0 && (
						<Plate
							withoutPadding
							className={styles.dPlate}
						>
							<EntityLinks links={{...presaleCheck?.links, objectId}} />
						</Plate>
					)}
				</div>
				<div className={classNames(styles.dColumn, styles.dColumnEntities)}>
					{presaleCheck && <ActionsDesktop />}

					<Plate withoutPadding>
						<PresaleCheckEntitiesTable
							columns={columns}
							objectId={objectId}
							presaleCheckId={presaleCheckId}
							selectedRows={selectedRows}
							onSelectedRowsChange={setSelectedRows}
						/>
					</Plate>
				</div>
			</div>
		</div>
	);
};
