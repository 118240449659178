import {formExportResponse} from '@src/utils/formExportResponse';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export const requestGetPresaleCheckAttachment = async (
	fileName: string,
	fileType: string,
	fileKey: string
): Promise<{
	fileName: string;
	fileContent: Blob;
	warnings?: Record<string, unknown>;
}> => {
	const response = await httpRequests.fileWithToken.get<Blob>('presale-checks/attachment', {
		params: {fileName, fileType, fileKey}
	});
	return formExportResponse(response);
};
