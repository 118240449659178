import {ReactNode, useCallback} from 'react';
import {
	convertCustomFieldToSave,
	errorsFns,
	isEdited
} from '../../components/EditCustomFieldForm/context/formState';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {ICustomField} from '@tehzor/tools/interfaces/fields';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {useAddCustomFiled} from '@/entities/Fields/model/cache/mutations/useAddCustomField';
import {useEditCustomFiled} from '@/entities/Fields/model/cache/mutations/useEditCustomField';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {useEditingCustomField} from '../../components/EditCustomFieldForm/context/hook';
import {fieldsSettings} from '../../constants/fieldsSettings';
import {EditCustomFieldForm} from '@/features/Fields/components/EditCustomFieldForm/EditCustomFieldForm';
import {useUpdateEffect} from 'react-use';

interface IFieldsProps {
	field?: ICustomField;
	onSave?: () => void;
	onClose?: () => void;
}

export const useEditCustomFieldForm = ({
	field,
	onSave,
	onClose
}: IFieldsProps): [ReactNode, () => void, () => void, boolean] => {
	const [editingState, editingDispatch] = useEditingCustomField(field);

	const {mutateAsync: addCustomField, isPending: addIsPending} = useAddCustomFiled();
	const {mutateAsync: editCustomField, isPending: editIsPending} = useEditCustomFiled();

	const saving = oneArgIsTrue(addIsPending, editIsPending);

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: field
		});
	}, [field, editingDispatch]);

	useUpdateEffect(() => {
		if (field) {
			reset();
		}
	}, [field]);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}
		if (!isEdited(editingState, field)) {
			return undefined;
		}
		return convertCustomFieldToSave(editingState, field);
	}, [editingDispatch, editingState, field]);

	const handleSave = async () => {
		const savingData = getSavingData();
		if (savingData) {
			if (field) {
				await editCustomField({...savingData, fieldId: field.id});
			} else {
				await addCustomField(savingData);
			}
		}
		reset();
		if (onSave) {
			onSave();
		}
	};

	const handleClose = async () => {
		if (isEdited(editingState, field)) {
			if (!(await getClosingConfirmation())) {
				return;
			}
		}
		reset();
		if (onClose) {
			onClose();
		}
	};

	const content = (
		<>
			<EditCustomFieldForm
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				saving={saving}
				isEditing={!!field}
			/>
			{closingDialog}
		</>
	);

	return [content, handleSave, handleClose, saving];
};
