import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {deleteLocalCheck} from '@src/actions/local-entities/checks';
import {deleteLocalInternalAcceptance} from '@src/actions/local-entities/internalAcceptance';
import {deleteLocalOwnerAcceptance} from '@src/actions/local-entities/ownerAcceptance';
import {deleteLocalWarrantyClaim} from '@src/actions/local-entities/warrantyClaim';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useDeleteCheck} from '@src/core/hooks/mutations/checks/useDeleteCheck';

import {useDeleteInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useDeleteInternalAcceptance';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useDeletePresaleCheck} from '@/features/PresaleCheck/model/hooks/useDeletePresaleCheck';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: ISpaceEntity[];
}

const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const dispatch = useAppDispatch();
	const deleteOwnerAcceptance = useDeleteOwnerAcceptance();
	const {mutate: deleteCheck} = useDeleteCheck();
	const deletePresaleCheck = useDeletePresaleCheck();
	const {mutate: deleteInternalAcceptance} = useDeleteInternalAcceptance();
	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить нарушения/осмотры/проверки/обращения',
		'Вы действительно хотите удалить выбранные нарушения/осмотры/проверки/обращения?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			let subRowsNumber = 0;
			const objectsIds: string[] = [];
			for (const {type, data, subRows} of selectedEntities) {
				switch (type) {
					case 'problem':
						if (!subRowsNumber) {
							await dispatch(deleteProblem(data.objectId, data.id));
							objectsIds.push(data.objectId);
						}
						break;
					case 'inspection':
						if (!subRowsNumber) {
							await dispatch(deleteInspection(data.objectId, data.id));
						}
						break;
					case 'check':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteCheck({objectId: data.objectId, checkId: data.id});
						break;
					case 'presale-check':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deletePresaleCheck({objectId: data.objectId, presaleCheckId: data.id});
						break;
					case 'local-check':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalCheck(data.id));
						break;
					case 'internal-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteInternalAcceptance({
							objectId: data.objectId,
							internalAcceptanceId: data.id
						});
						break;
					case 'local-internal-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalInternalAcceptance(data.id));
						break;
					case 'warranty-claim':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteWarrantyClaim({objectId: data.objectId, warrantyClaimId: data.id});
						break;
					case 'local-warranty-claim':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalWarrantyClaim(data.id));
						break;
					case 'owner-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await deleteOwnerAcceptance(data.objectId, data.id);
						break;
					case 'local-owner-acceptance':
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalOwnerAcceptance(data.id));
						break;
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
