import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {ISavingCustomField} from '@tehzor/tools/interfaces/fields/ISavingCustomFieldt';
import {addCustomFieldRequest} from '@/shared/api/fields/addCustomField';

export const useAddCustomFiled = () =>
	useMutation({
		mutationKey: fieldsQueryKeys.add(),
		mutationFn: (data: ISavingCustomField) => addCustomFieldRequest(data),
		onError: () => addErrorToast('Ошибка', 'при добавлении пользовательского поля'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
