import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPresaleCheckVisibility, changeEntitiesVisibility} from '@/entities/PresaleCheck';
import VisibilityFilter from '@src/components/VisibilityFilter';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import styles from './AddingDesktop.module.less';

export const ActionsDesktop = () => {
	const {t} = useTranslation();

	const visibility = useAppSelector(extractPresaleCheckVisibility);

	const visibilityTitles = useMemo(
		() => ({
			problems: t('presaleChecksPage.actions.visibility.problems'),
			inspections: t('presaleChecksPage.actions.visibility.inspections')
		}),
		[t]
	);

	return (
		<div className={styles.desktopControls}>
			<div>
				<VisibilityFilter
					value={visibility}
					titlesMap={visibilityTitles}
					onChange={changeEntitiesVisibility}
				/>
			</div>
		</div>
	);
};
