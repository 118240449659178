import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IPresaleChecksPermissions {
	canAdd?: boolean;
	canDelete?: boolean;
	canEdit?: boolean;
}

/**
 * Возвращает полномочия для предпродажных проверок
 *
 * @param objectId id объекта
 * @param targetComponent таргет
 */
export const usePresaleChecksPermissions = (
	objectId?: string,
	targetComponent?: 'problems' | 'space' | 'check-list'
): IPresaleChecksPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		const addPermission = findPermission(
			user.roles,
			'presale-checks-add',
			UserRoleScopes.OBJECT,
			objectId
		);
		return objectId
			? {
					canAdd:
						addPermission !== undefined &&
						!(
							targetComponent === 'problems' &&
							addPermission.restrictions &&
							addPermission.restrictions['presale-checks-add-from-problems']
						) &&
						!(
							targetComponent === 'space' &&
							addPermission.restrictions &&
							addPermission.restrictions['presale-checks-add-from-space']
						) &&
						!(
							targetComponent === 'check-list' &&
							addPermission.restrictions &&
							addPermission.restrictions['presale-checks-add-from-check-list']
						),
					canDelete: hasPermission(
						user.roles,
						'presale-checks-delete',
						UserRoleScopes.OBJECT,
						objectId
					),
					canEdit: hasPermission(
						user.roles,
						'presale-checks-edit',
						UserRoleScopes.OBJECT,
						objectId
					)
			  }
			: {
					canAdd: false,
					canDelete: false,
					canEdit: false
			  };
	}, [objectId, user]);
};
