import {addErrorToast} from '@src/utils/toasts';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {editCustomFieldRequest} from '@/shared/api/fields/editCustomField';
import {IEditCustomField} from '@tehzor/tools/interfaces/fields/IEditCustomFieldt';

export const useEditCustomFiled = () =>
	useMutation({
		mutationKey: fieldsQueryKeys.edit(),
		mutationFn: (data: IEditCustomField) => editCustomFieldRequest(data),
		onError: () => addErrorToast('Ошибка', 'при редактировании пользовательского поля'),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
