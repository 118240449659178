import {useQuery} from '@tanstack/react-query';
import {ILinkedPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/ILinkedPresaleCheck';
import IError from '@tehzor/tools/interfaces/IError';
import {presaleChecksQueryKeys} from '../../../keys';

export const usePresaleCheck = (presaleCheckId?: string) => {
	return useQuery<ILinkedPresaleCheck, IError>({
		queryKey: [...presaleChecksQueryKeys.details(), presaleCheckId],
		meta: {
			error: 'при загрузке предпродажной проверки'
		},
		enabled: !!presaleCheckId
	});
};
