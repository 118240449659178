import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetPresaleCheckResponse {
	presaleCheck: IPresaleCheck;
}

/**
 * Возвращает предпродажную проверку
 *
 * @param presaleCheckId id проверки
 */

export const requestPresaleCheck = async (presaleCheckId: string) => {
	const response = await httpRequests.withToken.get<IGetPresaleCheckResponse>(
		`presale-checks/${presaleCheckId}`
	);
	return response.data;
};
