import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useCallback} from 'react';
import {useDeleteFieldsSettingsSet} from '@/entities/FieldsSettingsSets';

export const useDeleteFieldsSettingsSetsDialog = (setIds: string[]) => {
	const {mutateAsync: deleteFieldsSettingSet} = useDeleteFieldsSettingsSet();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Удалить наборы',
		'Вы действительно хотите удалить выбранные наборы?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const handleDelete = useCallback(async () => {
		const deletedSetsIds = [];
		if (await getDeleteConfirmation()) {
			for (const setId of setIds) {
				const deletedSet = await deleteFieldsSettingSet(setId);
				deletedSetsIds.push(deletedSet.id);
			}
		}
		return deletedSetsIds;
	}, [setIds, getDeleteConfirmation, deleteFieldsSettingSet]);

	return {deleteDialog, handleDelete};
};
